<template>
<div>
  <Header />
<div class="mt 3 mx-auto col-12	 col-md-10	 banner overflow-hidden px-0">
  
  <div class="row" >
      <div class="px-0">
        <img class="w-100 d-none d-md-block" :src="aboutContent.Banner.file" />
        <img class="w-100 d-block d-md-none" :src="aboutContent.Banner.file" />
      
      </div>
   
  </div>


 
        <div class="row mt-4 h-25">
           <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12" >
             <div class="row">
              <div class="col-1 ">
                <img src="@/assets/estrella.svg"  class="mt-2 workshop-title-icon" /> 
              </div>
              <div class="col-10">
                <span class="subtitle-text red-text ml-2"> {{aboutContent.Title}}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-0 col-0">
              <div class="blue-separator"> </div>
          </div>
        </div>
       <div class="row h-25 mt-3">
          
          <div class="col-4 col-lg-2 col-xl-2 col-md-2">
             <img class=" mt-2" src="@/assets/made-to-size.svg" />
          </div>
          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-8 col-8 pl-0 pr-5 px-lg-0 px-xl-4">
          
             <p class="home-text text-justify">{{aboutContent.Description}}</p>
<br>
<p class="home-text text-justify">
 {{aboutContent.Description2}}
</p>

                         
             
          </div>
        
       
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">

            <div class="embed-responsive embed-responsive-4by3">
              <video class="embed-responsive-item mt-lg-n4" controls autoplay muted>
                <source src="/about-boom.mp4" type="video/mp4">
                Your browser does not support HTML video.
              </video>
            </div> 
          </div>
        </div>
     

     
      
        <div class="row mt-4 h-25 mt-5">
          <div class="col-12 text-center">
              <div class="row d-flex justify-content-center">
                
                  <div class="col-1 col-lg-3 d-flex justify-content-end px-0">
                        
                  </div>
                  <div class="col-9 col-lg-6 px-0">
                    <img src="@/assets/estrella-roja.svg" class="quote-red-star mb-1" />
                    <span class="values-title align-bottom">   {{aboutContent.ValuesTitle}}   </span>
                    <img src="@/assets/estrella-roja.svg" class="quote-red-star mb-1" />
                  </div>
                <div class="col-1 col-lg-3 d-flex justify-content-start px-0">
                       
                  </div>
              </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mt-4 mb-5 h-25 flex">
          <div class="col-2">
            <div class="d-flex justify-content-center" > 
              <img class="value-badge " :src="aboutContent.Value1.file" />
            </div>
          </div>    

          <div class="col-2">
            <div class="d-flex justify-content-center" >  
              <img class="value-badge" :src="aboutContent.Value2.file" />
            </div>
           </div>  
         
         <div class="col-2">
            <div class="d-flex justify-content-center" >  
              <img class="value-badge" :src="aboutContent.Value3.file" />
            </div>
          </div>   

          <div class="col-2">
            <div class="d-flex justify-content-center" >  
              <img class="value-badge" :src="aboutContent.Value4.file" />
            </div>
           </div>  

          <div class="col-2">
            <div class="d-flex justify-content-center" >  
              <img class="value-badge" :src="aboutContent.Value5.file" />
            </div>
          </div>   
        </div>

        <div class="row d-none d-md-block">
            <div class="d-flex justify-content-center mb-4 mt-2">
              <button  v-on:click="showModal=true" class="contact-us-button">CONTACT US
                <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill>
              </button>
           </div>
        </div>

        <div class="row d-md-none">
            <div class="m-auto mb-4 mt-0">
              <button  v-on:click="showModal=true" class="contact-us-button-mobile">CONTACT US
                <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill>
              </button>
           </div>
        </div>
          




    <vue-final-modal v-model="showModal" :overlay-style="overlayStyle">
    
       <div class="row mt-1"> 
        <div class="col-md-9 col-10">
        </div>
        <div class="col-md-1 col-1" v-if="!formSuccess">
            <div v-on:click="showModal=false" class="clickable"> 
                <img src="@/assets/tache.svg" class="remove-x">
            </div>
        </div>
    </div>
    <div class="row" v-if="!formSuccess" >
        <div class=" col-10  m-auto modal__content" >
            <div  class="text-center">
            <span class="red-title red-text m-auto"> Contact Us</span><br>
            </div>
            <div class="row mt-2">
                <b-form class="col-12 col-lg-7 m-auto" >
                <div class="form-group">
                    <div class="row blue-form">
                <div for="name" class="col-4 form-label">*Name:</div>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                <div for="email" class="col-4 form-label">*Email:</div>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                         <div class="form-group">
                    <div class="row blue-form">
                    <label for="comments" class="col-lg-4">Comments:</label>
                        <div class="col-lg-7">
                            <textarea v-model="form.comments"  
                            rows="3" 
                            class="form-control blue-form comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2cee4;  color: #000000;
                                    font-weight: bold;
                                    font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;
                                    "
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">

                 <div class="col-7 col-md-6 form-check d-flex justify-content-center">
                        <div class="align-self-center">
                        <input type="checkbox" v-model="form.privacyPolicy" value=true class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1">
                            <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a>
                        </label>
                     </div>
                </div>

                 <div class="col-5 col-md-6 form-check px-0 d-flex justify-content-end">
                    <button  v-on:click.prevent="send()" :disabled="isFormfilled" :class="disabledClass" class="contact-us-button" for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>

                </div>
                
                 </b-form>
            </div>
            
        </div>
    </div>    
     <div v-if="formSuccess" class="row">
       <div class="col-10 col-md-4 mx-auto ">
          <div  class=" contact-us-button text-center mt-50">
            Format sent!
          </div>
       </div>
     </div>
</vue-final-modal>


    

 
       

</div>
   


  <Footer class="mt-5"/>
</div>
</template>

<script>
// S E R V I C E S




import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import {  VueFinalModal } from 'vue-final-modal'
import EmailService from "@/service/email"

import AboutService from "@/service/about"

  export default {
     components: {
       Header,
       Footer,VueFinalModal
  },
    data() {
      return {
        jobs: [],
        formSuccess:false,
        isLoged:false,
        showModal:false,
        aboutContent:{    
          Banner:{},
          Title: '',
          Description: '',
          Description2: '',
          Video: '',
          ValuesTitle: '',
          Value1: {},
          Value2: {},
          Value3: {},
          Value4: {},
          Value5: {}
        },
        form:{
            name:"",
            email:"",
            comments:"",
            privacyPolicy:false,
            subject:"About Us"
        },
        overlayStyle:{
            background:"#FFFFFF",
            opacity:0.9
        },
      }
    },
    

   computed:{

    isFormfilled(){
        if(this.form.name !="" &&
            this.form.email !="" &&
            this.form.comments !="" &&
            this.form.privacyPolicy
            ){     
                return false;
            }
            return true;

    },

        disabledClass(){
        if(this.isFormfilled){
            return "disabled-button";
        }
        return "clickable";
    }

    },

    watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            this.formSuccess = false;
            this.showModal = false;
          }, 3000);
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
      this.getAboutcontent();
    },

    methods:{
      getAboutcontent(){
        AboutService.getAboutContent().then((res)=>{
          this.aboutContent.Banner.file = '/img/'+res.data.Banner;
          this.aboutContent.Title = res.data.Title;
          this.aboutContent.Description = res.data.Description;
          this.aboutContent.Description2 = res.data.Description2;
          this.aboutContent.Video = res.data.Video;
          this.aboutContent.ValuesTitle = res.data.ValuesTitle;
          this.aboutContent.Value1.file = res.data.Value1;
          this.aboutContent.Value2.file = res.data.Value2;
          this.aboutContent.Value3.file = res.data.Value3;
          this.aboutContent.Value4.file = res.data.Value4;
          this.aboutContent.Value5.file = res.data.Value5;
        })
    },

      checkLogin(){
        if (sessionStorage.getItem("token")!= undefined && sessionStorage.getItem("token")!= "") {
          this.isLoged = true;
        }
      },

      send(){
        this.form.selectedGoals = this.selectedGoals;
          EmailService.sendEmail(this.form).then(()=>{
            this.form={};
            this.formSuccess = true;
          }).catch(()=>{
            console.log('error')
          })
      },


      logout(){
        sessionStorage.clear();
        this.isLoged = false;
      }
      
    }
  }
</script>

