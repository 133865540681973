import axios from 'axios';

function getAboutContent(){
  
    return axios({
        method: 'get',
        url: '/api/public/about.php'})
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});
}



export default{
    getAboutContent
};